
import Vue from "vue";

import http from "@/http";

import { Bounce, BounceResponse } from "@/interfaces";

import { mdiMagnify } from "@mdi/js";

import MyPager from "@/components/MyPager.vue";

export interface DataType {
  bounces: Bounce[];
  query: {
    keyword: string;
    page: number;
  };
  hasPrev: boolean;
  hasNext: boolean;
}

export default Vue.extend({
  components: {
    MyPager,
  },
  data(): DataType {
    return {
      bounces: [],
      query: {
        keyword: "",
        page: 0,
      },
      hasPrev: false,
      hasNext: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "エラーメール",
          disabled: true,
          to: "/bounces",
        },
      ];
    },
    icon() {
      return {
        mdiMagnify,
      }
    }
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { page, keyword } = this.$route.query;

      if (keyword) {
        this.query.keyword = keyword.toString();
      }
      if (page) {
        this.query.page = +page;
      }

      const { data } = await http.get<BounceResponse>("bounces", {
        params: {
          keyword: this.query.keyword,
          page: this.query.page,
        },
      });

      this.bounces = data.data;

      this.hasNext = !!data.next_page_url;
      this.hasPrev = !!data.prev_page_url;
      this.query.page = +data.current_page;
    },
    async submit() {
      await this.$router
        .replace({
          path: "/bounces",
          query: {
            keyword: this.query.keyword,
            page: this.query.page.toString(),
          },
        })
        .catch(() => {});
    },
    async next() {
      const page = +this.query.page;

      this.query.page = page + 1;

      await this.submit();
    },

    async prev() {
      const page = +this.query.page;

      this.query.page = page - 1;

      await this.submit();
    },
  },
});
